import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from '../environments/environment.prod';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent {
  title = 'SmartResto';
  showFooter = true;
 
  constructor(private router: Router) {
    
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url === '/error' || event.url === '/coming-soon' || event.url === '/presentation') {
          this.showFooter = false;
        } else {
          this.showFooter = true;
        }
      }
    });
  }
  
  ngOnInit(): void { 
    // let cc = window as any;
    //    cc.cookieconsent.initialise({
    //      palette: {
    //        popup: {
    //          background: "#164969"
    //        },
    //        button: {
    //          background: "#ffe000",
    //          text: "#164969"
    //        }
    //      },
    //      theme: "classic",
    //      content: {
    //        message: 'This website uses cookies to improve your experience.',
    //        dismiss: 'Got it!',
    //        link: 'Learn more',
    //        href: environment.link + "/dataprivacy" 
    //      }
    //    });
  }
}
